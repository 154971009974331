<template>
  <div>
    <b-card class="wrap-bg">
      <b-card-body
        class="d-flex justify-content-center align-items-center"
        style="min-height: 350px"
      >
        <b-card-text class="text-center">
          <h1 style="color: #7367f0">Dedicated Source Used on Website</h1>
          <span> Popular searches: <b>Attendance, Performance</b> </span>

          <b-form-input
            v-model="search"
            placeholder="Search for anything"
            class="mt-2"
          />
        </b-card-text>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col md="4">
        <b-card>
          <b-card-header class="d-flex align-items-center">
            <feather-icon icon="GridIcon" size="18" class="text-primary" />
            <b class="ml-1">Employee</b>
          </b-card-header>
          <b-list-group>
            <b-list-group-item
              class="d-flex align-items-center"
              style="border: none"
            >
              <b-link
                :to="{ name: 'knowledge-bases/category/article' }"
                class="text-secondary"
              >
                <feather-icon icon="CircleIcon" size="10" class="mr-1" />
                How to add New Employee?
              </b-link>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.wrap-bg {
  background-color: #e8e6f8;
  background-image: url(https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/banner.c2d23a97.png);
  background-size: cover;
}
</style>

<script>
export default {
  data() {
    return {
      search: "",
      knowledges: [],
    };
  },

  mounted() {
    this.setCookie("kb_category", this.$route.params.category, 1);
  },
};
</script>